<script setup lang="ts">
import type { Language } from "@shopware-pwa/types";
import { DEPOSIT_PRODUCT_IDS } from "./lib/constants";
import { getProduct } from "@shopware-pwa/api-client";

const { public: publicConfig } = useRuntimeConfig();

// Fallback SEO tags.
const title = "Welkom bij Kippie.";
const description =
	"Welkom bij Kippie. Bekijk ons assortiment, de laatste weekacties en bestel de lekkerste producten om af te halen in een van onze winkels of om thuis te laten bezorgen!";
const ogImage = `${publicConfig.siteUrl}/og-image.png`;

// hotfix create something durable with default language because of bug in samsung internet browser
const { getAvailableLanguages } = useInternationalization()
const { setLanguage } = useSessionContext()

const { data: languages } = await useAsyncData("languages", async () => {
	return await getAvailableLanguages();
});

const cookie = useCookie('sw-language-id')
cookie.value = languages.value?.elements?.[0]?.id as string
await setLanguage(languages.value?.elements?.[0] as Language);



useSeoMeta({
	titleTemplate: (titleChunk) => (titleChunk ? `Kippie.nl  - ${titleChunk}` : "Kippie.nl"),
	description,
	ogTitle: title,
	ogDescription: description,
	ogImage,
	ogSiteName: "Kippie",
	ogLocale: "nl_NL",
	twitterTitle: title,
	twitterImage: ogImage,
	twitterCard: "summary_large_image"
});

const shopwareApi = useShopwareApi();

onMounted(() => {
	DEPOSIT_PRODUCT_IDS.forEach((id) => {
		preloadAsyncData(`shopware:product:${id}`, async () => (await getProduct(id, {}, shopwareApi)).product);
	});
});

defineRobotMeta();
</script>

<template>
	<div>
		<NuxtLayout>
			<NuxtLoadingIndicator color="repeating-linear-gradient(to right,#FFD932 0%,#FFE781 50%,#FFD932 100%)" />
			<NuxtPage :keepalive="{
				max: 30
			}" />
			<SnackbarProvider />
			<DrawerProvider />
			<LazyFullScreenSearch />
			<LazyShoppingCartSidebar />
		</NuxtLayout>
	</div>
</template>
